import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { hasValue } from '@digital-magic/ts-common-utils'
import { TranslatedFileId, useFilesDownload } from '@api/endpoints/files'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Delete } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { Link } from '@controls/Link'

type Props = {
  title?: string
  translatedFileId: TranslatedFileId
  deleteDisabled?: boolean
  onDelete?: () => void
}

// TODO: Make download operation to be lazy (we don't want to download all the files on every page presentation)
export const DownloadTranslatedFiles: React.FC<Props> = ({ title, translatedFileId, deleteDisabled, onDelete }) => {
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const { t } = useTranslation()

  const downloadTranslatedFiles = useFilesDownload([translatedFileId.eng, translatedFileId.est, translatedFileId.rus], {
    onError: defaultErrorHandler
  })
  const translatedFilesUrls = React.useMemo(
    (): ReadonlyArray<string> => downloadTranslatedFiles.data?.map((f) => URL.createObjectURL(f)) ?? [],
    [downloadTranslatedFiles.data]
  )

  const onDeleteInternal = (confirmResult: boolean): void => {
    if (confirmResult && onDelete) {
      onDelete()
    }
  }

  const isLoading = downloadTranslatedFiles.isLoading

  return (
    <Box display="flex" alignItems="center" columnGap={2}>
      {hasValue(title) && <span>{title}</span>}
      <Link href={translatedFilesUrls[0]} target="_blank" rel="noopener" download>
        {t('app.languages.en')}
      </Link>
      <Link href={translatedFilesUrls[1]} target="_blank" rel="noopener" download>
        {t('app.languages.et')}
      </Link>
      <Link href={translatedFilesUrls[2]} target="_blank" rel="noopener" download>
        {t('app.languages.ru')}
      </Link>
      {hasValue(onDelete) && (
        <ButtonWithConfirmation
          color="error"
          size="small"
          onConfirmResult={onDeleteInternal}
          disabled={isLoading || deleteDisabled === true}
          confirmTitle={t('global.consent.delete.title')}
          confirmMessage={t('global.consent.delete.message')}
        >
          <Delete />
        </ButtonWithConfirmation>
      )}
    </Box>
  )
}
