import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ObjProxyArg } from 'ts-object-path'
import { hasValue } from '@digital-magic/ts-common-utils'
import { Language } from '@api/endpoints/types'
import { Delete } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormFileInput, useFormInputProps } from '@controls/Form'

type Props = {
  title?: string
  nameEng: ObjProxyArg<FileList, FileList>
  nameEst: ObjProxyArg<FileList, FileList>
  nameRus: ObjProxyArg<FileList, FileList>
  accept?: string
  disabled?: boolean
  onDelete: (lang: Language) => void
}

type UploadLanguageProps = {
  title: string
  name: ObjProxyArg<FileList, FileList>
  accept?: string
  disabled?: boolean
  onDelete: () => void
  onInput: () => void
}

const UploadLanguage: React.FC<UploadLanguageProps> = ({ title, name, accept, disabled, onDelete, onInput }) => {
  const { t } = useTranslation()

  const onDeleteInternal = (confirmResult: boolean): void => {
    if (confirmResult) {
      onDelete()
    }
  }

  return (
    <>
      <Grid item xs={2}>
        {title}
      </Grid>
      <Grid item xs={8}>
        <FormFileInput accept={accept} name={name} disabled={disabled} onInput={onInput} />
      </Grid>
      <Grid item xs={2}>
        <ButtonWithConfirmation
          color="error"
          size="small"
          disabled={disabled}
          confirmTitle={t('global.consent.delete.title')}
          confirmMessage={t('global.consent.delete.message')}
          onConfirmResult={onDeleteInternal}
        >
          <Delete />
        </ButtonWithConfirmation>
      </Grid>
    </>
  )
}

export const UploadTranslatedFiles: React.FC<Props> = ({
  title,
  nameEng,
  nameEst,
  nameRus,
  accept,
  disabled,
  onDelete
}) => {
  const { t } = useTranslation()
  const f = useFormContext()
  const inputProps = useFormInputProps({ name: nameEng })

  const onInputInternal = (): void => {
    f.clearErrors(inputProps.name)
  }

  const onDeleteInternal = (lang: Language) => (): void => {
    onDelete(lang)
    f.clearErrors(inputProps.name)
  }

  return (
    <Grid container columnSpacing={{ xs: 6, md: 8 }} rowSpacing={2}>
      {hasValue(title) && (
        <Grid item xs={12}>
          {title}
        </Grid>
      )}
      <UploadLanguage
        title={t('app.languages.en')}
        name={nameEng}
        disabled={disabled}
        accept={accept}
        onInput={onInputInternal}
        onDelete={onDeleteInternal(Language.enum.ENG)}
      />
      <UploadLanguage
        title={t('app.languages.et')}
        name={nameEst}
        disabled={disabled}
        accept={accept}
        onInput={onInputInternal}
        onDelete={onDeleteInternal(Language.enum.EST)}
      />
      <UploadLanguage
        title={t('app.languages.ru')}
        name={nameRus}
        disabled={disabled}
        accept={accept}
        onInput={onInputInternal}
        onDelete={onDeleteInternal(Language.enum.RUS)}
      />
    </Grid>
  )
}
